<template>
  <div id="hello" class="hello">
    <v-row align="center">
      <v-col cols="12" sm="3" offset-sm="0" class="zero_padding">
        <v-row justify="end">
          <img class="leftimg" style="max-height: 100px;min-height: 80px" src="../../public/mcot_logo_app.png"
               alt="WemMCOT LOGO"/>
        </v-row>
      </v-col>
      <v-col cols="12" sm="4" class="zero_padding">
        <v-row justify="start">
          <p class="text-justify font-weight-medium" style="display:inline;font-size:18px">
            The MCOT application discovers motifs co-occurrence with a spacer and with an overlap in a single ChIP-seq
            dataset
          </p>
        </v-row>
      </v-col>
    </v-row>

    <v-row style="margin-bottom: -50px">
      <v-col cols="0" md="1"/>
      <v-col cols="12" md="10">
        <v-row>

          <v-col cols="12" md="6" class="zero_padding">
            <v-row><b class="font-italic font-weight-medium">Upload or Enter DNA sequences in FASTA format
              <v-dialog v-model="fasta_help" width="700">
                <template v-slot:activator="{ on }">
                  <v-btn
                      icon
                      color="indigo"
                      v-on="on">
                    <v-icon>mdi-help</v-icon>
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title
                      class="headline grey lighten-2"
                      primary-title
                  >
                    FASTA format help
                  </v-card-title>

                  <v-card-text>
                    <p>DNA sequences of peaks in FASTA format are required to search the co-occurrence of motifs. Peaks
                      are commonly derived from ChIP-seq experiment. Each sequences begins with a single-line
                      description, followed by at least one line of sequence data. The description line is distinguished
                      from the sequence data by
                      the first symbol '>'.<br>Below an example shows two sequences in FASTA format:</p>

                    <div class="bio_card">>chr1 13257652 13257741
                      <br>gccgccgccgccaacgctgctgctggtgcc
                      <br>ttcgctcttcactgtcagtttcctgtttac
                      <br>atcatcacttcccccctttgataaggcaaa
                      <br>>chr1 40437905 40438036
                      <br>gggaccgcagaatgctgcgctgatgcagtt
                      <br>aaagagaaagaacgtcagggttaaggagca
                      <br>aggccagagacactggggaaggtaaataag
                      <br>agggctcacagctgggtatgagccagccaa
                      <br>gctaactagtga
                    </div>

                  </v-card-text>

                  <v-divider></v-divider>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        text
                        @click="fasta_help = false"
                    >
                      Close help
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>

            </b>
            </v-row>
            <v-row>
              <v-radio-group :disabled="this.dis" v-model="radio_fasta" row @change="seq_clean">
                <v-radio
                    label="Upload file"
                    color="primary"
                    value="file"/>
                <v-radio
                    label="Enter sequences"
                    color="primary"
                    value="text"/>
              </v-radio-group>
            </v-row>
            <v-row style="width: 90%" v-if="radio_fasta === 'file'">
              <input ref="fasta_ref" v-if="example_mode === false" type="file" :disabled="this.dis"
                     :placeholder="seq_text"
                     @change="seq_click">
              <p v-else-if="example_mode === true && seq !== null"><a href="https://www.bcgsc.ca/downloads/chiptf/mouse/FoxA2/"
                                                      target="_blank">{{ seq_text }}</a> supported by
                <a href="https://academic.oup.com/nar/article-lookup/doi/10.1093/nar/gkn382" target="_blank">previous
                  analysis</a></p>
              <p v-else-if="example_mode === true">Please, wait...</p>
            </v-row>
            <v-row style="width: 90%" v-else-if="radio_fasta === 'text'">
              <v-textarea filled
                          :disabled="this.dis"
                          v-model="seq"
                          color="teal"

              >
                <template v-slot:label>
                  <div>
                    Enter FASTA sequences
                  </div>
                </template>
              </v-textarea>
            </v-row>

          </v-col>
          <v-col cols="12" md="6" class="zero_padding">
            <v-row><b class="font-italic font-weight-medium">Upload or Enter Anchor motif
              <v-dialog v-model="anchor_help" width="700">
                <template v-slot:activator="{ on }">
                  <v-btn
                      icon
                      color="indigo"
                      v-on="on">
                    <v-icon>mdi-help</v-icon>
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title
                      class="headline grey lighten-2"
                      primary-title
                  >
                    Motif format help
                  </v-card-title>

                  <v-card-text>
                    <p>Anchor motif refer to immunoprecipitated transcription factor.<br>
                      The format of motif starts with the header line with the first symbol '>'. Next each line
                      represents frequencies of nucleotides A/C/G/T in consequent positions of motifs, i.e. besides the
                      header line the number of lines in a file of a motif is equal to the length of motif. Below an
                      example shows the format of motif.</p>
                    <div class="bio_card">>RUNX1
                      <br>0.001&#9;0.001&#9;0.001&#9;0.997
                      <br>0.001&#9;0.001&#9;0.997&#9;0.001
                      <br>0.001&#9;0.001&#9;0.001&#9;0.997
                      <br>0.001&#9;0.001&#9;0.997&#9;0.001
                      <br>0.001&#9;0.001&#9;0.997&#9;0.001
                      <br>0.001&#9;0.001&#9;0.001&#9;0.997
                      <br>0.001&#9;0.117&#9;0.001&#9;0.881
                      <br>0.188&#9;0.001&#9;0.001&#9;0.810
                    </div>

                  </v-card-text>

                  <v-divider></v-divider>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        text
                        @click="anchor_help = false"
                    >
                      Close help
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </b>
            </v-row>

            <v-row>
              <v-radio-group :disabled="this.dis" v-model="radio_anchor" row @change="anchor_clean">
                <v-radio
                    label="Upload file"
                    color="primary"
                    value="file"/>
                <v-radio
                    label="Enter motif"
                    color="primary"
                    value="text"/>
                <v-radio
                    label="Choose motif"
                    color="primary"
                    value="choose"/>
              </v-radio-group>
            </v-row>
            <v-row v-if="radio_anchor === 'file'">
              <input ref="motif_ref" v-if="example_mode === false" type="file" @change="motif_click"/>
              <p v-else>{{ motif_text }}</p>
            </v-row>
            <v-row v-else-if="radio_anchor === 'text'">
              <v-textarea :disabled="this.dis"
                          filled
                          v-model="motif"
                          color="teal"
              >
                <template v-slot:label>
                  <div>
                    Enter Anchor motif
                  </div>
                </template>
              </v-textarea>
            </v-row>
            <v-row v-else-if="radio_anchor === 'choose'">
              <v-select :disabled="this.dis"
                        filled
                        v-model="choosen_anchor_motif"
                        :items="current_motif_set"
                        label="Select the partner motif from database"
                        item-value="number"
                        item-text="value">

              </v-select>
            </v-row>


          </v-col>

        </v-row>

        <div style="padding-top: 20px"></div>

        <v-row>
          <v-col cols="12" md="6" class="zero_padding" style="min-height: 265px; padding-right: 0;">
            <v-row>
              <p class="font-italic font-weight-medium" style="margin-bottom: 0;">One or Many Partner motif(s) will be
                tested
                <v-dialog v-model="one_many_help" width="750">
                  <template v-slot:activator="{ on }">
                    <v-btn
                        icon
                        color="indigo"
                        v-on="on">
                      <v-icon>mdi-help</v-icon>
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title
                        class="headline grey lighten-2"
                        primary-title
                    >
                      Select whether sole or multiple partner motifs will be analyzed
                    </v-card-title>

                    <v-card-text>
                      ‘One-partner’ option allows to test two motifs (Anchor and Partner) for co-occurrence, while ‘Many
                      partners’ option tests the co-occurrence of Anchor motif with the list of partner motifs from a
                      public database.<br>
                      See Help page <a href="/help">(link)</a> for detail
                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                          color="primary"
                          text
                          @click="one_many_help = false"
                      >
                        Close help
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </p>
            </v-row>
            <v-row justify="start" class="zero_padding">

              <v-radio-group row :disabled="this.dis" v-model="radio" @change="radio_change">
                <v-radio
                    label="One partner"
                    color="primary"
                    value="one"/>
                <v-radio
                    label="Many partners"
                    color="primary"
                    value="many"/>

              </v-radio-group>
            </v-row>
            <v-row justify="start">
              <v-menu
                  v-model="min_max_modal"
                  :close-on-content-click="false"
                  max-width="400"
                  max-height="280"
                  offset-x>
                <template v-slot:activator="{ on }">
                  <v-btn outlined v-on="on">
                    <div class="font-italic font-weight-medium"
                         style="letter-spacing: 0;text-indent: 0; text-transform: none; font-size: 16px;">Advanced
                      options
                    </div>
                  </v-btn>
                  <v-dialog v-model="min_max_help" width="500">
                    <template v-slot:activator="{ on }">
                      <v-btn icon color="indigo" v-on="on">
                        <v-icon>mdi-help</v-icon>
                      </v-btn>

                    </template>
                    <v-card>
                      <v-card-title>
                        Select the range of spacer lengths
                      </v-card-title>
                      <v-card-text>
                        The minimal and maximal spacer lengths can restrict the search of spaced locations of motifs
                        (the default values are 0 and 29 nt).
                        WebMCOT allows the variation of the upper limit of spacer length up to 100 base pairs.
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" text @click="min_max_help = false">Close help</v-btn>
                      </v-card-actions>
                    </v-card>

                  </v-dialog>
                </template>
                <v-card>
                  <v-list>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>Range of spacer length
                          <v-btn icon color="indigo">
                            <v-icon>mdi-help</v-icon>
                          </v-btn>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>

                  <v-divider></v-divider>
                  <v-list>
                    <v-list-item>
                      <v-list-item-content>
                        <v-row>
                          <v-col cols="6">
                            <v-text-field
                                filled
                                :disabled="this.dis"
                                v-model="number_min"
                                type="number"
                                min="0"
                                max="100"
                                label="Minimum"/>
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                                filled
                                :disabled="this.dis"
                                v-model="number_max"
                                type="number"
                                min="0"
                                max="100"
                                label="Maximum"/>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="6">
                            <v-text-field
                              filled
                              :disabled="this.dis"
                              v-model="selection_threshold"
                              type="number"
                              min="0.000001"
                              label="Expected Recognition Rate">

                            </v-text-field>
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                              filled
                              :disabled="this.dis"
                              v-model="significance_threshold"
                              type="number"
                              label="CE enrichment">
                            </v-text-field>
                          </v-col>

                        </v-row>
                        <v-row>
                          <v-col cols="12">
                            <v-text-field
                              filled
                              :disabled="this.dis"
                              v-model="conservatism_threshold"
                              type="number"
                              label="Threshold for Asymmetry Ratio"></v-text-field>
                          </v-col>
                        </v-row>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>

                  <v-card-actions>
                    <v-btn color="primary" text @click="min_max_modal = false">Close</v-btn>
                  </v-card-actions>

                </v-card>
              </v-menu>
            </v-row>
          </v-col>


          <v-col cols="12" md="6" class="zero_padding" style="padding-right: 0;  padding-top: 0;">
            <div v-if="radio === 'many'">
              <v-row>
                <b class="font-italic font-weight-medium">Database of Partner motifs
                  <v-dialog v-model="many_database_help" width="750">
                    <template v-slot:activator="{ on }">
                      <v-btn
                          icon
                          color="indigo"
                          v-on="on">
                        <v-icon>mdi-help</v-icon>
                      </v-btn>
                    </template>
                    <v-card>
                      <v-card-title
                          class="headline grey lighten-2"
                          primary-title
                      >
                        Database of Partner motifs
                      </v-card-title>

                      <v-card-text>
                        <p><a href="http://hocomoco11.autosome.ru/">Hocomoco</a> human/mouse core (396/353) and full
                          (747/509) collections of motifs and 514 A. thaliana motifs from <a
                              href="http://neomorph.salk.edu/dap_web/pages/index.php">Plant Cistrome</a></p>
                      </v-card-text>

                      <v-divider></v-divider>

                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="primary"
                            text
                            @click="many_database_help = false"
                        >
                          Close help
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </b>
              </v-row>
              <v-row>
                <v-select :disabled="this.dis"
                          filled
                          v-model="db"
                          @change="many_change"
                          label="Select the database with the proven recognition models of known transcription factors"
                          :items="db_item"/>
              </v-row>
            </div>
            <div v-else-if="radio === 'one'">
              <v-row>
                <b class="font-italic font-weight-medium">Upload or Enter Partner motif
                  <v-dialog v-model="partner_help" width="700">
                    <template v-slot:activator="{ on }">
                      <v-btn
                          icon
                          color="indigo"
                          v-on="on">
                        <v-icon>mdi-help</v-icon>
                      </v-btn>
                    </template>
                    <v-card>
                      <v-card-title
                          class="headline grey lighten-2"
                          primary-title
                      >
                        Motif format help
                      </v-card-title>
                      <v-card-text>
                        <p>Partner motif is the second motif of a pair.<br>
                          The format of motif starts with the header line with the first symbol '>'. Next each line
                          represents frequencies of nucleotides A/C/G/T in consequent positions of motifs, i.e. besides
                          the header line the number of lines in a file of a motif is equal to the length of motif.
                          Below an example shows the format of motif.</p>

                        <div class="bio_card">>RUNX1
                          <br>0.001&#9;0.001&#9;0.001&#9;0.997
                          <br>0.001&#9;0.001&#9;0.997&#9;0.001
                          <br>0.001&#9;0.001&#9;0.001&#9;0.997
                          <br>0.001&#9;0.001&#9;0.997&#9;0.001
                          <br>0.001&#9;0.001&#9;0.997&#9;0.001
                          <br>0.001&#9;0.001&#9;0.001&#9;0.997
                          <br>0.001&#9;0.117&#9;0.001&#9;0.881
                          <br>0.188&#9;0.001&#9;0.001&#9;0.810
                        </div>
                        <p><br>The threshold for recognition model (Position Weight Matrix) is specified according to a
                          preliminary selected expectation value of False Positive Rate. Hence, the species-specific
                          whole-genome dataset of promoters is required to compute the dependence between False Positive
                          Rate and threshold.<br>
                          See Help page <a href="/help">(link)</a> for detail </p>


                      </v-card-text>
                      <v-divider></v-divider>

                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="primary"
                            text
                            @click="partner_help = false"
                        >
                          Close help
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </b>

              </v-row>
              <v-row>
                <v-select :disabled="this.dis"
                          v-model="organism"
                          filled
                          label="Select Organism to set thresholds of recognition models"
                          :items="organism_items"
                          item-text="text"
                          item-value="value"
                          style="margin-bottom: -25px;"
                          @change="organism_change"
                />
              </v-row>
              <v-row>
                <v-col style="padding-left: 0; padding-top: 0; padding-bottom: 0;">
                  <v-radio-group
                      :disabled="this.dis"
                      v-model="radio_partner"
                      row
                      @change="radio_partner_change">
                    <v-radio
                        label="Upload file"
                        color="primary"
                        value="file"/>
                    <v-radio
                        label="Enter motif"
                        color="primary"
                        value="text"/>
                    <v-radio
                        label="Choose motif"
                        color="primary"
                        value="choose"/>
                  </v-radio-group>
                </v-col>


              </v-row>

              <v-row>
                <div v-if="radio_partner === 'file'" style="padding-right: 0;">
                  <input ref="motif_sec_ref" v-if="example_mode === false" type="file" @change="motif_second"/>
                  <p v-else>{{ motif_sec_text }}</p>
                </div>
                <v-textarea :disabled="this.dis" v-else-if="radio_partner === 'text'"
                            v-model="motif_sec"
                            color="teal" filled>
                  <template v-slot:label>
                    <div>
                      Enter partner motif
                    </div>
                  </template>
                </v-textarea>
                <v-select :disabled="this.dis"
                          filled
                          v-model="choosen_motif"
                          :items="current_motif_set"
                          label="Select the partner motif from database"
                          item-value="number"
                          item-text="value"
                          v-else-if="radio_partner === 'choose'">
                </v-select>
              </v-row>

            </div>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="0" md="1"/>
    </v-row>


    <v-divider style="margin-top: 15px"></v-divider>
    <v-row>
      <v-col cols="0" md="1"/>
      <v-col cols="12" md="10">
        <v-row justify="start">
          <v-col cols="2">
            <v-row>
              <v-btn color="primary" v-on:click="run" :disabled="dis" block>RUN</v-btn>
            </v-row>
          </v-col>
          <v-col cols="2">
            <v-btn color="primary" v-on:click="clean" block>{{dis === true ? "STOP AND CLEAN" : "CLEAN"}}</v-btn>
          </v-col>
          <v-col cols="8"><p v-if="task_id !== null">
            Your task is allocated with this URL: <a :href="task_url">{{ task_url }}</a></p>
            <p>{{many_log}}
          </p>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="3">
            <v-row justify="start">
              <v-btn color="primary" :disabled="dis" @click="load_example" small>
                Example
              </v-btn>

            </v-row>
          </v-col>

        </v-row>
      </v-col>
      <v-col cols="0" md="1"/>
    </v-row>


    <v-row>
      <v-col cols="0" md="1"/>
      <v-col cols="12" md="10">
        <v-row v-if="pvalue_table !== null">
          <v-col>
            <v-btn class="text-none" :href="download_input()">Download Input files</v-btn>
          </v-col>
          <v-col>
            <v-btn class="text-none" :href="download_pval()">Download P-value table</v-btn>
          </v-col>
          <v-col>
            <v-btn class="text-none" :href="download_hist()">Download Histogram data</v-btn>
          </v-col>
          <v-col>
            <v-btn class="text-none" :href="download_additional()">Download additional data</v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="0" md="1"/>
    </v-row>

    <v-row v-if="pvalue_table !== null">
      <v-col>
        <v-data-table
            :headers="fields"
            :items="pvalue_table"
            multi-sort>

          <!-- eslint-disable-next-line -->
          <template v-slot:header="props">
            <thead>
            <tr>
              <th colspan="1">Motif Name</th>
              <th colspan="5">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <span v-on="on">CE significance, -Log10(P-value)</span>
                  </template>
                  <span>Significance of co-occurrence Anchor/Partner, -Log10[P-value]</span>
                </v-tooltip>

              </th>
              <th colspan="1">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <span v-on="on">Similarity</span>
                  </template>
                  <span>Similarity to Anchor, -Log10[P-value]</span>
                </v-tooltip>

              </th>
              <th colspan="2">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <span v-on="on">Asymmetry, -Log10(P-value)</span>
                  </template>
                  <span>Significance of asymmetry to Anchor+/Partner-, -Log10[P-value]</span>
                </v-tooltip>

              </th>
              <th colspan="1">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <span v-on="on">CE histogram</span>
                  </template>
                  <span>Distribution of motifs co-occurrence</span>
                </v-tooltip>

              </th>
              <th colspan="1">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <span v-on="on">CE logo</span>
                  </template>
                  <span>The most common CE type with an overlap of motifs</span>
                </v-tooltip>

              </th>
              <th colspan="5">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <span v-on="on">Asymmetry heatmap, per mille</span>
                  </template>
                  <span>Asymmetry Anchor vs. Partner, [Observed - Expected], per mille</span>
                </v-tooltip>

              </th>
            </tr>
            </thead>
          </template>


          <!-- eslint-disable-next-line -->
          <template v-for="h in fields" v-slot:[`header.${h.value}`]="{ header }">
            <!-- eslint-disable-next-line -->
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <span v-on="on">{{ h.text }}</span>
              </template>
              <span>{{ h.value }}</span>
            </v-tooltip>
          </template>


          <!-- eslint-disable-next-line -->
          <template v-slot:item.action="{ item }">
            <v-col cols="6">
              <Histogram v-bind:index="pvalue_table.indexOf(item)" v-bind:task="task_id"/>
            </v-col>
          </template>
          <!--                  <v-btn icon v-on:click="download_diagram(item)"><v-icon>mdi-cloud-download</v-icon></v-btn>-->
          <!-- eslint-disable-next-line -->
          <template v-slot:item.image="{ item }">
            <v-col cols="6" v-if="pvalue_table.indexOf(item) !== 0">
              <Motif v-bind:index="item['Number']" v-bind:task="task_id"/>
            </v-col>
            <v-col cols=6 v-else>
              <Motif index="0" v-bind:task="task_id"/>
            </v-col>
          </template>
          <!-- eslint-disable-next-line -->
          <template v-slot:item.matrix_full="{ item }">
            <v-col cols="6" v-if="
            (matrices[item['Number']] !== undefined) &&
            matrices[item['Number']].includes('Full') ">
              <v-btn icon v-on:click="download_matrix(item, 'Full')">
                <v-icon>mdi-cloud-download</v-icon>
              </v-btn>
            </v-col>
          </template>

          <!-- eslint-disable-next-line -->
          <template v-slot:item.matrix_any="{ item }">
            <v-col cols="6" v-if="
            matrices[item['Number']] !== undefined &&
            matrices[item['Number']].includes('Any')">
              <v-btn icon v-on:click="download_matrix(item, 'Any')">
                <v-icon>mdi-cloud-download</v-icon>
              </v-btn>
            </v-col>
          </template>

          <!-- eslint-disable-next-line -->
          <template v-slot:item.matrix_overlap="{ item }">
            <v-col cols="6" v-if="
            matrices[item['Number']] !== undefined &&
            matrices[item['Number']].includes('Overlap')">
              <v-btn icon v-on:click="download_matrix(item, 'Overlap')">
                <v-icon>mdi-cloud-download</v-icon>
              </v-btn>
            </v-col>
          </template>

          <!-- eslint-disable-next-line -->
          <template v-slot:item.matrix_spacer="{ item }">
            <v-col cols="6" v-if="
            matrices[item['Number']] !== undefined &&
            matrices[item['Number']].includes('Spacer')">
              <v-btn icon v-on:click="download_matrix(item, 'Spacer')">
                <v-icon>mdi-cloud-download</v-icon>
              </v-btn>
            </v-col>
          </template>

          <!-- eslint-disable-next-line -->
          <template v-slot:item.matrix_partial="{ item }">
            <v-col cols="6" v-if="
            matrices[item['Number']] !== undefined &&
            matrices[item['Number']].includes('Partial')">
              <v-btn icon v-on:click="download_matrix(item, 'Partial')">
                <v-icon>mdi-cloud-download</v-icon>
              </v-btn>
            </v-col>
          </template>
        </v-data-table>
      </v-col>
    </v-row>


  </div>
</template>

<script>
/* eslint-disable */


import SvgIcon from './SvgIcon'
import Motif from './Motif'
import Histogram from "./Histogram";
// you probably need to import built-in style
// import 'vue-range-slider/dist/vue-range-slider.css'
import CssConfig from './CssTable'
import swal from 'sweetalert'

const WAIT_SEC = 3000;

const DB_ITEMS = [
  {value: {db: 'hs_core_11', org: 'hs'}, text: 'Hocomoco v.11 Human core collection'},
  {value: {db: 'mm_core_11', org: 'mm'}, text: 'Hocomoco v.11 Mouse core collection'},
  {value: {db: 'hs_core_12', org: 'hs'}, text: 'Hocomoco v.12 Human core collection'},
  {value: {db: 'mm_core_12', org: 'mm'}, text: 'Hocomoco v.12 Mouse core collection'},
  {value: {db: 'dapseq', org: 'at'}, text: 'Plant Cistrome Arabidopsis collection'},
  {value: {db: 'jaspar24_at10', org: 'at'}, text: 'JASPAR2024 Plants collection'},
  {value: {db: 'jaspar24_dm6', org: 'dm'}, text: 'JASPAR2024 Insects collection'}
];


export default {
  name: 'HelloWorld',
  computed: {
    db_item: () => (DB_ITEMS),
    task_url: function () {
      return `${window.location.protocol + "//" + window.location.host}/app?q=${this.task_id}`;
    },
    current_motif_set: function () {
      return this.motif_lists[this.organism];
    }
  },
  mounted() {
    if (this.$route.query.q !== undefined) {
      this.task_id = this.$route.query.q;
      this.dis = true;
      this.get_result();
    }
    const keys = Object.keys(this.motif_lists);
    for (let i in keys) {
      fetch(`/api/get/${keys[i]}_motifs`).then(response => {
        response.json().then(json => this.motif_lists[keys[i]] = json)
      })
    }
  },
  data() {
    return {
      many_log: null,
      matrices: null,
      pagination: {
        sortBy: 'name'
      }, example_mode: false,
      choosen_motif: undefined,
      choosen_anchor_motif: undefined,
      fasta_help: false,
      motif_lists: {hs: [], mm: [], at: []},
      anchor_help: false,
      partner_help: false,
      one_many_help: false,
      many_database_help: false,
      min_max_help: false,
      min_max_modal: false,
      seq_text: '',
      motif_text: '',
      motif_sec_text: '',
      showDialog: false,
      css: CssConfig,
      console: console,
      msg: 'Welcome to MCOT',
      radio: "many",
      radio_fasta: 'file',
      radio_anchor: 'file',
      pvalue_table: null,
      organism_items: [
        {text: 'Human', value: 'hs'},
        {text: 'Mouse', value: 'mm'},
        {text: 'Arabidopsis', value: 'at'}
      ],
      //
      // 'AncPar Overlap', 'AncEq Overlap', 'ParEq Overlap', '', 'AncPar Spacer', 'AncEq Spacer', 'ParEq Spacer']
      fields: [

        // {text: '# Motif', value: '# Motif'},
        {text: "", value: "Motif Name", sortable: false},
        {text: 'Full', value: 'Full overlap, -Log10[P-value]'},
        {text: 'Partial', value: 'Partial overlap,-Log10[P-value]'},
        {text: 'Overlap', value: 'Overlap, -Log10[P-value]'},
        {text: 'Spacer', value: 'Spacer, -Log10[P-value]'},
        {text: 'Any', value: 'Any, -Log10[P-value]'},
        {text: '-Log10(P-value)', value: 'Similarity to Anchor, -Log10[P-value]'},

        
        {text: 'Overlap', value: 'Overlap, Sites Asymmetry/Symmetry, -Log10[P-value]'},
        {text: 'Spacer', value: 'Spacer, Sites Asymmetry/Symmetry, -Log10[P-value]'},
        
        {text: '', value: 'action', sortable: false},
        {text: '', value: 'image', sortable: false},


        {text: 'Full', value: 'matrix_full', sortable: false},
        {text: 'Partial', value: 'matrix_partial', sortable: false},
        {text: 'Overlap', value: 'matrix_overlap', sortable: false},
        {text: 'Spacer', value: 'matrix_spacer', sortable: false},
        {text: 'Any', value: 'matrix_any', sortable: false},


      ],
      number_min: 0,
      number_max: 29,
      organism: 'hs',
      seq: null,
      motif: null,
      motif_sec: null,
      radio_partner: 'file',
      db: DB_ITEMS[0]['value'],
      task_id: null,
      dis: false,
      image: null,
      onWaitingResults: null,
      isTest: false,
      selection_threshold: 0.0005,
      significance_threshold: 5,
      conservatism_threshold: 1.5,
    }
  },
  // sockets: {
  //   connect() {
  //
  //   },
  //   finished () {
  //     console.log(`result status:`);
  //
  //   }
  // },
  components: {
    Motif,
    SvgIcon,
    Histogram

  },

  methods: {
    output_config() {
      console.log(process.env);
      console.log(process.env.VUE_APP_WEBMCOT_HOST)
    },
    seq_click: function () {
      this.seq = this.$refs.fasta_ref.files[0];
    },
    organism_change() {
      this.choosen_motif = undefined;
    },
    load_example() {
      this.example_mode = true;
      this.radio_fasta = 'file';
      this.radio_anchor = 'text';
      this.radio_partner = 'text';
      this.radio = 'many';
      this.isTest = true;
      this.organism = 'mm';
      this.db = {db: 'mm_core_11', org: 'mm'};
      fetch('/example/hnf1b.motif', {
        method: 'get'
      }).then((response) => {
        response.text().then((blob) => {
          this.motif_sec = blob;
          this.motif_sec_text = 'Partner motif ETS1, GSM449525'
        })
      });

      fetch('/example/foxa2.motif', {
        method: 'get'
      }).then((response) => {
        response.text().then((blob) => {
          this.motif = blob;
          this.motif_text = 'Anchor motif FOXA2'
        })
      });

      fetch('/example/wederell_foxa2_liver_mm8.seq', {
        method: 'get'
      }).then((response) => {
        response.blob().then((blob) => {
          this.seq = blob;
          this.seq_text = 'ChIP-seq data for FOXA2'
        })
      });
    },
    seq_clean() {
      this.seq = null;
    },
    radio_partner_change() {
      this.motif_sec = null;
    },
    motif_click: function () {
      this.motif = this.$refs.motif_ref.files[0];
    },
    radio_change() {
      this.organism = this.db['org'];
    },
    anchor_clean() {
      this.motif = null;
    },
    motif_second: function () {
      this.motif_sec = this.$refs.motif_sec_ref.files[0]
    },
    many_change(env) {
      this.organism = env['org'];
    },
    clean: function () {
      this.dis = false;
      this.pvalue_table = null;
      this.seq = undefined;
      this.choosen_anchor_motif = undefined;
      this.motif = undefined;
      this.number_min = 0;
      this.number_max = 30;
      this.choosen_motif = undefined;
      this.motif_sec = undefined;
      this.example_mode = undefined;
      this.task_id = null;
      this.many_log = null;
      this.$router.push({path: this.$router.currentRoute.fullPath, query: {q: undefined}})
    },
    run: function () {
      this.dis = true;
      let formData = new FormData();
      formData.append('seq', this.seq);
      if (this.choosen_anchor_motif !== undefined) {
        formData.append('select_anchor_motif', this.choosen_anchor_motif)
      } else {
        formData.append('motif', this.motif);
      }

      formData.append('min_space', this.number_min);
      formData.append('max_space', this.number_max);
      formData.append('organism', this.organism);
      formData.append('selection_threshold', this.selection_threshold)
      formData.append('significance_threshold', this.significance_threshold)
      formData.append('conservatism_threshold', this.conservatism_threshold)
      if (this.radio === "one") {
        if (this.choosen_motif !== undefined) {
          formData.append('select_motif', this.choosen_motif)
        } else {
          formData.append('motif_sec', this.motif_sec);
        }
      } else {
        formData.append('organism_db', this.db['db'])
      }
      fetch(`/api/send_${this.radio}`, {
        method: 'post',
        body: formData,
      }).then((response) => {
        if (response.ok) {
          return response.text()
        }
        response.json().then(t => {
          swal('Error', t['message']);
          this.dis = false
        })

      }).then((text) => {
        this.task_id = text;
        this.pvalue_table = null;
        this.$nextTick(() => {
          this.$router.push({path: this.$router.currentRoute.fullPath, query: {q: this.task_id}})
          setTimeout(this.get_result, WAIT_SEC);
        })


      }).catch(
          error => {
            this.dis = false;
          }
      )
    },
    download_input() {
      return `/api/get/${this.task_id}/input_files`
    },
    download_pval() {
      return `/api/get/${this.task_id}/pval_file`
    },
    download_hist() {
      return `/api/get/${this.task_id}/hist_file`
    },
    download_additional() {
      return `/api/get/${this.task_id}/archive_file`;
    },
    download_diagram: function (i) {
      const index = this.pvalue_table.indexOf(i);
      window.open(`/api/get/${this.task_id}/hist/${index}`, '_blank')

    },
    download_matrix: function (i, type_matrix) {
      console.log(i['Motif Name']);
      const index = i['Number'];
      window.open(`/api/get/${this.task_id}/matrix/${index}/${type_matrix}`, '_blank')

    },
    download_motif: function (i) {
      const index = i['Number'];
      window.open(`/api/get/${this.task_id}/motif/${index}`, '_blank')
    },
    get_result: function () {
      if (this.dis === true) {
        let url = new URL(`/api/get/${this.task_id}/status`, window.location.origin);
        fetch(url.toString(), {
          method: 'get'
        }).then(response => {
          return response.json();
        }).then(json_result => {
          if (json_result['state'] === 'PENDING') {
            if ('log' in json_result) {
              this.many_log = json_result['log'];
            }
            setTimeout(this.get_result, WAIT_SEC);
          } else if (json_result['state'] === 'SUCCESS') {
            fetch(`/api/get/${this.task_id}/hist/count`, {
              method: 'get'
            }).then(response => {
              return response.text()
            }).then(text => {
              fetch(`/api/get/${this.task_id}/pval`, {
                method: 'get'
              }).then(response => {
                return response.json();
              }).then(json => {
                this.pvalue_table = json;
              })

              fetch(`/api/get/${this.task_id}/motif/matrices`, {
                method: 'get'
              }).then(response => response.json())
              .then(json => {
                this.matrices = json;
              })
            }).catch(error => {
              swal('Error', JSON.stringify(error))
            }).finally(() => {
              this.dis = false;
            })
          } else if (json_result['state'] === 'FAILURE' && json_result['text'] !== undefined) {
            console.log("ERROR");
            throw Error(`${json_result['text']}`);
          } else {
            setTimeout(this.get_result, WAIT_SEC);
          }
        }).catch(error => {
          if (error.message !== undefined) {
            swal('Error', error.message);
          } else {
            swal('Error', 'Unknown error')
          }
          this.dis = false;
        }).finally(() => {
        });
      }


    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
table th + th {
  border-left: 1px solid #dddddd;
}

table td + td {
  border-left: 1px solid #dddddd;
}
</style>
<style>
.shadow:hover {
  background: #fc0; /* Цвет фона */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); /* Параметры тени */

}

.slider {
  /* overwrite slider styles */
  width: 890px;
}

.file {
  width: 100px;
}

.example {
  position: absolute;
  left: 5px;
  top: 50px;
}

fieldset {
  padding: 10px;
  border: #fafafa;
}

legend {

}

.zero_padding {
  padding-bottom: 0;
  padding-top: 0;
}

.bio_card {
  font-family: Courier, monospace;
  white-space: pre;
  font-size: 16px;
  overflow: auto
}
</style>
