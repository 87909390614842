import { render, staticRenderFns } from "./Motif.vue?vue&type=template&id=0544d7d8&scoped=true&"
import script from "./Motif.vue?vue&type=script&lang=js&"
export * from "./Motif.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../.yarn/__virtual__/vue-loader-virtual-f90b86ed5d/0/cache/vue-loader-npm-15.9.3-4ca89c7e4b-eec86deeea.zip/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0544d7d8",
  null
  
)

export default component.exports