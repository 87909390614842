<template>
  <div v-if="img === 'WAIT'">
    Computing, please wait…
  </div>
  <div  v-else-if="img !== null">
    <a target="_blank" :href="`/api/get/${task}/motif/${index}`">
      <img class="shadow" v-bind:alt="`${index} motif`" v-bind:src="img" width="150" height="100"/>
    </a>
<!--    <v-img :aspect-ratio="16/9" v-bind:src="`/api/get/${task}/motif/${index}`"/>-->
  </div>
  <div v-else>
    <span>Overlapped pairs with more conserved partner motif are absent</span>
  </div>
</template>

<script>

let get_motif = async function(task_id, index) {
    let response = await fetch(`/api/get/${task_id}/motif_base64/${index}`)
    if (response.ok) {
        return await response.text();
    } else {
        throw new Error("Motif not found");
    }
}




export default {
  name: "Motif",
  data() {
    return {
      img: null
    }

  },

  created() {
    this.upload_motif_img(this.index)
  },
  methods: {
    upload_motif_img: function (index) {
      this.img = 'WAIT';
      this.$nextTick().then(() => {
        get_motif(this.task, index).then((b) => {
          this.img = `data:image/png;base64, ${b}`;
        }).catch(() => {
          this.img = null;
        })
      })

    }
  },
  props: {
    task:{
      type: String,
      required: true
    },
    index:{
      type: String,
      required: true
    }
  },
  watch:{
    index: function (newVal, oldVal){
      this.upload_motif_img(newVal);
    }
  }

}
</script>

<style scoped>

</style>
